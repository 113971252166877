<div class="row tvs-footer pt-5">
  <div class="container-fluid">
    <div class="row">
      <div class="tvs-col-35 mt-0 mt-sm-5 text-center">
        <a href="" target="_self" aria-label="Botón inicio">
          <img class="tvs-logo mx-auto img img-fluid" src="assets/images/website_general/logo-televisaunivisionllega.png" alt="Botón inicio" width="161" height="55"/>
        </a>
      </div>
      <div class="d-block d-sm-none col-12 tvs-footer-div-h">
        <div>&nbsp;</div>
      </div>
      <div class="tvs-col-25 mt-5 text-left tvs-border-right pl-5 pr-2 d-none d-lg-block">
        <div class="tvs-text-white tvs-text-upper mb-4 h4-footer ">Menú</div>
        <ul class="p-0">
          <li>
            <a (click)="navigateTo('')">Inicio</a>
          </li>
          <li>
            <a (click)="navigateTo('nuestra-oferta')">Nuestra oferta</a>
          </li>
          <li>
            <a (click)="navigateTo('plan-comercial')">Plan Comercial</a>
          </li>
          <li>
            <a (click)="navigateTo('paquetes')">Paquetes</a>
          </li>
          <li>
            <a (click)="navigateTo('futurecast')">Futurecast</a>
          </li>
          <li>
            <a (click)="navigateTo('acerca-de')">Acerca de</a>
          </li>
          <li>
            <a (click)="navigateTo('eventos')">Eventos</a>
          </li>
          <li>
            <a (click)="navigateTo('contacto')">Contacto</a>
          </li>
        </ul>
      </div>
      <div class="tvs-col-25 mt-5 text-left tvs-border-right pl-5 pr-2">
        <div class="tvs-text-white tvs-text-upper mb-4 h4-footer">Información legal</div>
        <ul class="p-0">
          <li>
            <a id="link-politicas-tv-radiodifundida" href="https://televisallega.com/assets/2025/politicas_tv_radiodifundida/Politicas_TV_Radiodifundida_2025.pdf?c=1" target="_blank">
              Políticas TV Radiodifundida
            </a>
          </li>
          <li>
            <a id="link-politicas-tv-de-paga" href="https://televisallega.com/assets/2025/politicas_tv_paga/Politicas_TV_Paga_2025.pdf?c=1" target="_blank">
              Políticas TV de Paga
            </a>
          </li>
          <li>
            <a id="link-politicas-tv-regional" href="https://televisallega.com/assets/2025/politicas_tv_regional/Politicas_TV_Regional_2025.pdf?c=1" target="_blank">
              Políticas TV Regional
            </a>
          </li>
          <li>
            <a id="manual-y-formatos" href="https://televisallega.com/assets/2025/manual_y_formatos.zip" target="_blank">
              Manual y Formatos
            </a>
          </li>
          <li>
            <a id="link-aviso-privacidad" href="aviso-privacidad" target="_self">
              Aviso de privacidad
            </a>
          </li>
          <li>
            <a id="link-aviso-cookies" href="aviso-privacidad/cookies" target="_self">
              Aviso de cookies
            </a>
          </li>
        </ul>
      </div>
      <div class="d-block d-sm-none col-12 tvs-footer-div-h">
        <div>&nbsp;</div>
      </div>
      <div class="tvs-col-35 mt-5 text-left pl-5">
        <div class="tvs-text-white tvs-text-upper mb-4 h4-footer ">Síguenos</div>
        <div class="col-12 text-left px-0">
          <a id="link-facebook" href="https://www.facebook.com/TelevisaUnivision" target="_blank">
            <img class="tvs-rrss-icon mr-2" src="assets/images/website_general/icono-facebook.svg" alt="Facebook" />
          </a>
          <a id="link-instagram" href="https://www.instagram.com/accounts/login/?next=/televisaunivision/" target="_blank">
            <img class="tvs-rrss-icon mr-2" src="assets/images/website_general/icono-instagram.svg" alt="Instagram" />
          </a>
          <a id="link-twitter" href="https://twitter.com/TeleUniPRTeam" target="_blank">
            <img class="tvs-rrss-icon mr-2" src="assets/images/website_general/icono-twitter-x.svg" alt="Twitter" />
          </a>
          <a id="link-linkedin" href="https://www.linkedin.com/company/televisaunivision/mycompany/?viewAsMember=true" target="_blank">
            <img class="tvs-rrss-icon mr-2" src="assets/images/website_general/icono-linkedin.svg" alt="LinkedIn" />
          </a>
        </div>
      </div>
      <div class="d-block d-sm-none col-12 tvs-footer-div-h">
        <div>&nbsp;</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-4 text-center">
        <p class="tvs-text-white mt-4 mb-0 small">TelevisaUnivision D.R./Derechos reservados © Televisa, S. de R.L. de C.V. {{currentYear}}.</p>
      </div>
    </div>
  </div>
</div>
