
// _ _ _   ___ __  __ ____   ___  ____ _____  _    _   _ _____ _____   _ _ _
// | | | | |_ _|  \/  |  _ \ / _ \|  _ \_   _|/ \  | \ | |_   _| ____| | | | |
// | | | |  | || |\/| | |_) | | | | |_) || | / _ \ |  \| | | | |  _|   | | | |
// |_|_|_|  | || |  | |  __/| |_| |  _ < | |/ ___ \| |\  | | | | |___  |_|_|_|
// (_|_|_) |___|_| _|_|_|    \___/|_| \_\|_/_/   \_\_| \_| |_| |_____| (_|_|_)
// IMPORTANTE
//            _                                    _       _
//  __ _  ___| |_(_)_   ____ _    __ _ _ __   __ _| |_   _| |_(_) ___ ___
// / _` |/ __| __| \ \ / / _` |  / _` | '_ \ / _` | | | | | __| |/ __/ __|
//| (_| | (__| |_| |\ V / (_| | | (_| | | | | (_| | | |_| | |_| | (__\__ \
// \__,_|\___|\__|_| \_/ \__,_|  \__,_|_| |_|\__,_|_|\__, |\__|_|\___|___/
//                                                   |___/
// activa analytics para producción

//   ____ _               __               _       _         __            ____             _
//  / ___| |_ _ __ ___   |  \/  | ___   __| |_   _| | ___   / _| ___  _ __|  _ \ ___   ___ | |_
// | |  _| __| '_ ` _ \  | |\/| |/ _ \ / _` | | | | |/ _ \ | |_ / _ \| '__| |_) / _ \ / _ \| __|
// | |_| | |_| | | | | | | |  | | (_) | (_| | |_| | |  __/ |  _| (_) | |  |  _ < (_) | (_) | |_
//  \____|\__|_| |_| |_| |_|  |_|\___/ \__,_|\__,_|_|\___| |_| _\___/|_|  |_| \_\___/ \___/ \__|
// variable GtmModule.forRoot

//   __ _ _ __  _ __     __ _ _ __  _ __   _ __ ___   ___   __| |_   _| | ___ | |_ ___
//  / _` | '_ \| '_ \   / _` | '_ \| '_ \ | '_ ` _ \ / _ \ / _` | | | | |/ _ \| __/ __|
// | (_| | |_) | |_) | | (_| | |_) | |_) || | | | | | (_) | (_| | |_| | |  __/| |_\__ \
//  \__,_| .__/| .__/   \__,_| .__/| .__(_)_| |_| |_|\___/ \__,_|\__,_|_|\___(_)__|___/
//       |_|   |_|           |_|   |_|
// en src/app/app.module.ts


// --------------------------------------------------------------------------------------------------

export const environment = {
  production: true,
  //baseUrl: "https://apidev.televisallega.com",

  // NOTA NOTA NOTA NOTA NOTA NOTA NOTA NOTA
  // CONFIGURA GtmModule.forRoot en
  // src app app.module.ts
  baseUrl: "https://api.televisallega.com",
  //CMS PROD e INTERNO
  baseCMSUrl: "https://cms.televisallega.com/",

  //CMS QA y DEV
  // baseCMSUrl: "https://qacms.televisallega.com/",
  tokenUrl: null, // For IdentityServer/Authorization Server API. You can set to null if same as baseUrl
  loginUrl: '/login',
  linkedInClientId: "",
  linkedInRedirectUri: "",
  linkedInSecretId: "",
};
